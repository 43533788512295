export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  COLLECTION_PAGE: "collection-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  FELLOWSHIP_PAGE: "fellowship-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  AUTHOR_PAGE: "author-page",
  NEWSLETTER_PAGE: "newsletter-page",
  COMPANY_PAGE: "company-page",
  SUBSCRIPTION_PAGE: "subscription-page",
  SUBSCRIPTION_CHECKOUT: "subscription-checkout",
  CAMPAIGN_SUBSCRIPTION_CHECKOUT: "campaign-subscription-checkout",
  GIFT_SUBSCRIPTION: "gift-subscription",
  // TRIAL_SUBSCRIPTION_PAGE: "trial-subscription-page",
  INDEPENDENCE_DAY_OFFER_PAGE: "independence-day-offer-page",
  VALENTINE_PAGE: "valentines-page",
  PAYMENT_CHECKOUT_PAGE: "checkout-page",
  PODCAST_LETTERS: "podcast-letters",
  ACCOUNT_DETAILS_PAGE: "account-page",
  STUDENT_SUBSCRIPTION_PAGE: "student-subscription-page",
  DIWALI_MERCH: "diwali-merch-page",
  SAVED_PAGE: "saved-page",
  CHRISTMAS_OFFER: "christmas-offer-page",
  FIGHT_TO_BREATHE: "fight-to-breathe",
  MERCH_PAGE: "merch-page",
  PODCAST_RSS: "podcast-rss",
  LOGIN_PAGE: "login-page",
  FAQ_PAGE: "faq-page",
  ABOUT_US_PAGE: "about-us-page",
  TEAM_PAGE: "team-page",
  CONTACT_US_PAGE: "contact-us-page",
  STUDENT_SUBSCRIPTION_EXPLAINED_PAGE: "student-sub-explained-page",
  SPONSOR_STUDENT_SUBSCRIPTION_PAGE: "sponsor-student-subscription",
  ART_AUCTION_PAGE: "art-auction-page",
  GRIEVANCE_REDRESSAL: "grievance-redressal",
  DOWNLOAD_APP: "download-app",
  GROUP_SUBSCRIPTION: "group-subscription",
  SPECTRUM_PAGE: "spectrum-page",
  VOTING_PAGE: "voting-page",
  SPECTRUM_RESULTS_PAGE: "spectrum-results-page",
  ANGEL_SUBSCRIBERS: "angel-subscribers",
  HINDI_SUBSCRIPTION_PAGE: "hindi-subscription-page",
  FRIENDS_OF_NL: "friends-of-nl",
  HINDI_WHATSAPP_OPTIN: "hindi-whatsapp-optin",
  COMBINED_SENA: "combined-sena",
  "2024_ELECTION_FUND": "2024-election-fund",
  RESULTSDAY_LIVE: "electionslive",
  PHOTO_GALLERY: "photo-gallery",
  SETTINGS_PAGE: "settings-page",
});
export const TAG_PAGE_URL_PREFIX = "/topic/";

export const SUBSCRIPTION_PAGE_URL = "/subscription";
export const SUBSCRIPTION_CHECKOUT_URL = "/subscription/checkout";
export const GIFT_SUBSCRIPTION_URL = "/gift";
export const CAMPAIGN_SUBSCRIPTION_CHECKOUT_URL = "/campaign/checkout";

// export const TRIAL_SUBSCRIPTION_PAGE_URL = "/trial-subscription";

export const INDEPENDENCE_DAY_OFFER_PAGE_URL = "/independence-day-offer";

export const PAYMENT_CHECKOUT_URL = "/payment-checkout";

export const ACCOUNT_DETAILS_URL = "/account-details";
export const STUDENT_SUBSCRIPTION_URL = "/student-subscription";

export const storyFields =
  "id,headline,subheadline,slug,url,story-audio,metadata,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,author-name,author-id,sections,story-template,cards,tags,authors,access";

export const mobileStoryFields =
  "id,headline,subheadline,slug,url,story-audio,metadata,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,published-at,author-name,author-id,sections,story-template,cards,tags,authors,access";

export const newsletterData = [
  {
    listId: "b7fd7fb036",
    description:
      "A new weekly newsletter by Manjul where he comments, critiques and questions in one of the oldest formats in journalism: cartoons.",
  },
  {
    listId: "3cf030021e",
    description:
      "Newslaundry Events: From The Media Rumble to NL Chats and webinars, updates and details on latest Newslaundry events.",
  },
  {
    listId: "c6b749d171",
    description:
      "A weekly guide to the best of our stories from our editors and reporters. Note: Skip if you're a subscriber. All subscribers get a weekly, subscriber-only newsletter by default.",
  },
  {
    listId: "aa2c780e6c",
    description:
      "न्यूज़लॉन्ड्री हिन्दी के साप्ताहिक संपादकीय, चुनिंदा बेहतरीन रिपोर्ट्स, टिप्पणियां और मीडिया की स्वस्थ आलोचनाएं.",
  },
  {
    listId: "82e2052fcc",
    description:
      "A weekly capsule of our podcasts, part of some of India's most-followed podcasts on media, politics, pop culture, food and more.",
  },
  {
    listId: "a2ab56c30d",
    description:
      "A weekly newsletter by Chitranshu Tewari to help you track the news media ecosystem and make sense of it. See the latest edition here.",
  },
  {
    listId: "72e185d922",
    description:
      "न्यूज़लॉन्ड्री हिन्दी के रोज़नामचा न्यूज़लेटर के लिए साइन अप करें और पढ़िए हिंदी के प्रमुख अख़बारों की सुर्खियों का एक जायजा",
  },
];
